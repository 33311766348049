import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./Footer.css";

function Footer() {
  const now = new Date().valueOf();

  return (
    <footer className="footer navbar-fixed-bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <p className="text-center text-muted">
              Copyright ©{" "}
              <FormattedDate value={now} year="numeric"></FormattedDate>
            </p>
          </div>
        </div>
      </div>
      <div id="inner-footer" className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <address>
                <strong>David Lacasse</strong>
                <br />
                <p className="text-muted">
                  <FormattedMessage id="CurrentLocation"></FormattedMessage>
                </p>
                <a href="tel:+4507757734" className="text-muted">
                  <i className="fa fa-phone-square" aria-hidden="true"></i>{" "}
                  (450)775-7734
                </a>
                <br />
                <a href="mailto:info@dlacasse.com" className="link text-muted">
                  <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                  info@dlacasse.com
                </a>
              </address>
            </div>
            <div className="col-md-6 text-right footer-social">
              <a
                href="https://facebook.com/davidxlacasse"
                className="social-icons"
              >
                <i className="custom-fa fa fa-facebook"></i>
              </a>
              <a
                href="https://github.com/davidlacasse"
                className="social-icons"
              >
                <i className="custom-fa fa fa-github" aria-hidden="true"></i>
              </a>
              <a
                href="https://linkedin.com/in/david-lacasse"
                className="social-icons"
              >
                <i className="custom-fa fa fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/david___lacasse/"
                className="social-icons"
              >
                <i className="custom-fa fa fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
