import './App.css';
import Header from './Header';
import styled from 'styled-components';
import Portfolio from './Portfolio';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import React from 'react';

const Container = styled.div`
  margin-top: 70px;

  @media print {
      margin-top: 25px;
  }
`;

export const appRef = React.createRef<HTMLDivElement>();

function App() {
  return (
    <div ref={appRef} id="content">

      <Helmet>
        <title>David Lacasse - Portfolio</title>
      </Helmet>

      <Header></Header>

      <div className="content">
        <div className="content-inside">
          <Container>
            <Portfolio></Portfolio>
          </Container>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default App;
