import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import French from './fr.json';
import English from './en.json';
import { flatten } from 'flat';


export const Context = React.createContext<{ locale: string, selectLanguage: (locale: string) => void }>({} as any);

const local = navigator.language;

let lang: any;
if (local.indexOf('fr') !== -1) {
    lang = flatten(French);
} else {
    lang = flatten(English);
}

export const useLocale = () => {
    const context = useContext(Context);
    return context.locale;
};

export const useSetLocale = () => {
    const constext = useContext(Context);
    return constext.selectLanguage;
}


const Wrapper = (props: any) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(newLocale: string) {
        setLocale(newLocale);
        if (newLocale.indexOf('fr') !== -1) {
            setMessages(flatten(French));
        } else
            setMessages(flatten(English));
    }


    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}

export default Wrapper;