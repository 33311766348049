import React from 'react'
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useLocale, useSetLocale } from './IntlProvider'

export const Button = styled.a`
    :hover{
        cursor:pointer;
    }
`;

interface LanguageButtonProps {
    className?: string;
}

const LanguageButton: React.FunctionComponent<LanguageButtonProps> = (props) => {
    const setLocale = useSetLocale();
    const locale = useLocale();

    let nextLocale = '';
    if (locale.indexOf('fr') !== -1) {
        nextLocale = 'en'
    } else {
        nextLocale = 'fr';
    }


    return (
        <Button className={props.className} onClick={() => setLocale(nextLocale)}><FormattedMessage id={nextLocale}></FormattedMessage></Button>
    )
}

export default LanguageButton