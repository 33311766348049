import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components';

interface JobDetails {
    Name: string;
    City: string;
    Internship?: boolean;
    Current?: boolean;
    Roles?: Role[]
    StartDate: number;
    EndDate?: number;
}

interface Role {
    Title: string;
    StartDate: number;
    EndDate?: number;
    Responsabilities: string[];
    Tags: string[];
}

interface JobProps {
    details: JobDetails;
    className?: string;
}

const Roles = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
`;

function Job(props: JobProps) {

    const startDate = new Date(props.details.StartDate);
    const endDate = props.details.EndDate ? new Date(props.details.EndDate) : new Date();

    const sameYear = startDate.getFullYear() === endDate.getFullYear();

    return (
        <div className={props.className}>
            <div id="jobContainer">
                <div className="row" style={{ marginTop: '35px' }}>
                    <div className="col-6">
                        <h4>
                            <FormattedMessage id={props.details.Name}></FormattedMessage><br />
                        </h4>
                    </div>

                    {!props.details.Current && sameYear && <div className="offset-4 col-2 text-right">
                        <h4><FormattedDate value={startDate} year='numeric'></FormattedDate></h4>
                    </div>}
                    {!props.details.Current && !sameYear &&
                        <div className="offset-1 col-5 text-right">
                            <h4>
                                <FormattedDate value={startDate} year='numeric'></FormattedDate> - <FormattedDate value={endDate} year='numeric'></FormattedDate>
                            </h4>
                        </div>
                    }
                    {
                        props.details.Current && <div className="col-6 text-right">
                            <h4><FormattedDate value={startDate} year='numeric'></FormattedDate> - <FormattedMessage id="today" />
                            </h4>
                        </div>}
                </div>
                <Roles>
                    {props.details.Roles?.map(r =>
                        <Role role={r} />
                    )}
                </Roles>
            </div >

        </div >
    )
}

interface RoleProps {
    role: Role;
}

const StyledDiv = styled.div`

            `;

export const Role = (props: RoleProps) => {
    const startDate = new Date(props.role.StartDate);
    const endDate = props.role.EndDate ? new Date(props.role.EndDate) : new Date();

    const sameYear = startDate.getFullYear() === endDate.getFullYear();

    return (
        <StyledDiv>
            <div className="row">

                <div className="col-6">
                    <h5>
                        <small className="text-muted"><FormattedMessage id={props.role.Title}></FormattedMessage></small>
                    </h5>
                </div>
            </div>
            <div className="row responsabilities">
                <ul>
                    {props.role.Responsabilities.map(r => <li> <FormattedMessage id={r}></FormattedMessage></li>)}

                </ul>
            </div>

            <div className="row">
                <div className="col">
                    {props.role.Tags.map(t => <span
                        className="badge badge-secondary"
                        style={{ margin: '3px' }}
                    >{t}</span>)}
                </div>
            </div >
        </StyledDiv>
    )

}


export default Job  
