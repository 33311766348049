import React from "react";
import { FormattedMessage } from "react-intl";
import Formation from "./Formation";
import Job from "./Job";
import formations from "./data/formations.json";
import jobs from "./data/experiences.json";

function Portfolio() {
  return (
    <div className="container">
      <div id="portfolio" className="row">
        <div id="jobs" className="col-md-7" style={{ marginBottom: "25px" }}>
          <h2>
            <FormattedMessage id="ProfessionnalExperience"></FormattedMessage>
          </h2>
          <hr />
          {jobs
            .sort((a, b) => {
              if (a.Current) return -1;
              if (b.Current) return 1;

              return a.EndDate! > b.EndDate! ? -1 : 1;
            })
            .map((j) => (
              <Job className="asdf" details={j}></Job>
            ))}
        </div>

        <div id="experiences" className="offset-md-1 col-md-4">
          <h2>
            <FormattedMessage id="Experience"></FormattedMessage>
          </h2>
          <hr />
          {formations.map((f) => (
            <Formation details={f}></Formation>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
