import React from 'react'
import { FormattedDate, FormattedDateParts, FormattedMessage } from 'react-intl'

interface FormationDetails {
    Description: string;
    Institution: string;
    StartDate: number;
    EndDate: number;
}

interface FormationProps {
    className?: string;
    details: FormationDetails
}


const Formation = (props: FormationProps) => {

    const startDate = new Date(props.details.StartDate);
    const endDate = new Date(props.details.EndDate);

    const sameYear = startDate.getFullYear() === endDate.getFullYear();

    return (
        <div className={props.className}>
            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-8">
                    <h5><FormattedMessage id={props.details.Description}></FormattedMessage> <br /><small className="text-muted"><FormattedMessage id={props.details.Institution}></FormattedMessage></small></h5>
                </div>
                {sameYear && <div className="col-4 text-right">
                    <b><FormattedDate value={startDate} year='numeric'></FormattedDate></b>
                </div>}
                {!sameYear && <div className="col-4 text-right">
                    <b><FormattedDate value={startDate} year='numeric'></FormattedDate> - <FormattedDate value={endDate} year='numeric'></FormattedDate></b>
                </div>}
                {/* <div  *ngIf="FormationDetails.SameYear()">
                <b>{{ FormationDetails.StartDate | date:'yyyy'}}</b>
            </div>
            <div className="col-4 text-right" *ngIf="!FormationDetails.SameYear()">
            <b>{{ FormationDetails.StartDate | date:'yyyy'}} - {{ FormationDetails.EndDate | date:'yyyy'}}</b> */}
            </div>
        </div>



    )
}


export default Formation