import React from "react";
import { FormattedMessage } from "react-intl";
import LanguageButton, { Button } from "./i18n/languageButton";
import "./Header.css";
import { appRef } from "./App";
import ReactToPrint from "react-to-print";

function Header() {
  return (
    <header>
      <div className="containter-fluid">
        <div className="jumbotron">
          <div className="container">
            <h1 className="display-4">David Lacasse</h1>
            <p className="lead">
              <FormattedMessage id="softwareDevloper"></FormattedMessage>
            </p>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-md sticky sticky-top navbar-dark bg-dark">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <ReactToPrint
                  content={() => appRef.current}
                  trigger={() => (
                    <Button className="nav-link">
                      <FormattedMessage id="print"></FormattedMessage>
                    </Button>
                  )}
                ></ReactToPrint>
              </li>
              <li className="nav-item">
                <LanguageButton className="nav-link"></LanguageButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
